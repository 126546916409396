import { useNavigate } from 'react-router-dom';

import { RoutePath } from '@/routes/RoutePath';

const useNavigateToAllFacilitiesLeads = () => {
  const navigate = useNavigate();

  const navigateToAllFacilitiesLeads = () => {
    const targetUrl = `${RoutePath.CRMScreenerAllLeads}?all_facilities=true`;

    navigate(targetUrl);
  };

  return navigateToAllFacilitiesLeads;
};

export default useNavigateToAllFacilitiesLeads;
