import React from 'react';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { Person } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';

import { postS3Files, UploadProgressState } from '@/api/postS3Files';
import { useSnackbar } from '@/hooks/useSnackbar';

import { AvatarS3PhotoKey } from './ui/Avatar/AvatarS3PhotoKey';
import { UploadCircularProgress } from './CircularProgress';

interface ControlledProfilePictureProps<TFields extends FieldValues> {
  name: FieldPath<TFields>;
  formMethods: UseFormReturn<TFields>;
  handleOnChange?: VoidFunction;
}

export function ControlledProfilePicture<TFields extends FieldValues>({
  name,
  formMethods,
  handleOnChange
}: React.PropsWithChildren<ControlledProfilePictureProps<TFields>>) {
  const [progress, setProgress] = React.useState({} as UploadProgressState);
  const s3PhotoKey = formMethods.watch(name) as string;
  const { showSnackbar } = useSnackbar();

  const handlePhotoUpload: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    try {
      const s3files = await postS3Files(event.target.files, setProgress);
      formMethods.setValue(name, s3files[0].key as any, { shouldDirty: true });
      handleOnChange?.();
    } catch (error) {
      showSnackbar({
        message: 'Error uploading profile photo. Please try again',
        severity: 'error'
      });
    }
  };

  const handlePhotoDelete = () => {
    formMethods.setValue(name, null as any, {
      shouldDirty: true
    });
    handleOnChange?.();
  };

  return (
    <Box display="flex" alignItems="center">
      {progress.file && (
        <>
          <UploadCircularProgress size={88} value={progress.loaded} />
          <Typography color="#667A86" fontSize="0.875rem" ml={2} mb="8px">
            {progress.file.name}
          </Typography>
        </>
      )}
      {!progress.file && (
        <>
          <AvatarS3PhotoKey
            sx={{
              background: '#FDFEFF',
              border: s3PhotoKey ? 'none' : '1px solid #667A86',
              width: 88,
              height: 88,
              mr: '12px'
            }}
            s3PhotoKey={s3PhotoKey}>
            <Person htmlColor="#667A86" sx={{ fontSize: '3rem' }} />
          </AvatarS3PhotoKey>
          <Stack>
            <Typography color="#667A86" fontSize="0.875rem" mb="8px">
              Profile Image
            </Typography>
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              id="profile-picture-input"
              onChange={handlePhotoUpload}
            />
            <Box>
              <Button
                variant="contained"
                onClick={() =>
                  document.getElementById('profile-picture-input')?.click()
                }
                sx={{
                  borderColor: '#1DB8F2',
                  height: 30,
                  padding: '4px 10px',
                  lineHeight: '1',
                  fontSize: '13px'
                }}>
                Upload New Image
              </Button>
              {s3PhotoKey && (
                <Button
                  onClick={handlePhotoDelete}
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginLeft: 1,
                    height: 30,
                    fontSize: '13px'
                  }}>
                  Remove
                </Button>
              )}
            </Box>
          </Stack>
        </>
      )}
    </Box>
  );
}
