import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { useLocation, useNavigate } from 'react-router-dom';
import { show } from '@intercom/messenger-js-sdk';
import { ContactSupport, MenuOpen, Search } from '@mui/icons-material';
import {
  AppBar,
  CardMedia,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar
} from '@mui/material';

import exacareLogo from '@/images/exacare_logo.png';
import { RoutePath } from '@/routes/RoutePath';

import CurrentUser from '../CurrentUser';
import FacilitySelector from '../FacilitySelector';
import { showResidentGlobalSearchDialog } from '../ResidentGlobalSearchDialog';
import { TimeClockSidebarButton } from '../Timeclock/TimeclockSidebarButton';

import { CrmEhrModuleButton } from './CrmEhrModuleButton';
import { getSidebarCRMItems } from './getSidebarCRMItems';
import { getSidebarEHRItems } from './getSidebarEHRItems';
import { isCurrentPath, StyledNavButton } from './Sidebar';
import { SidebarItemBadge } from './SidebarItemBadge';

const MobileNavigationDialog: React.FC<InstanceProps<unknown>> = ({
  isOpen,
  onResolve
}) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const isCrmPath = pathname.includes('/crm/');
  const crmSidebarItems = getSidebarCRMItems();
  const ehrSidebarItems = getSidebarEHRItems();

  const sidebarItems = React.useMemo(() => {
    if (isCrmPath) {
      return crmSidebarItems;
    }
    return ehrSidebarItems;
  }, [pathname]);

  const navigateToUrl = (url: string) => {
    onResolve();
    navigate(url);
  };

  return (
    <StyledDialog fullScreen open={isOpen}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start">
            <MenuOpen onClick={onResolve} />
          </IconButton>
          <CardMedia component="img" src={exacareLogo} />
        </Toolbar>
      </AppBar>
      <List dense component="nav" disablePadding>
        {sidebarItems.map((item, index) => {
          const { icon, text, path, state, badgeProps, showOnMobile } = item;

          if (!showOnMobile) {
            return null;
          }

          return (
            <SidebarItemBadge expanded key={index} {...badgeProps}>
              <ListItem>
                <StyledNavButton
                  onClick={() => navigateToUrl(path)}
                  {...(state && { state })}
                  selected={isCurrentPath(item, pathname)}>
                  <ListItemIcon sx={{ minWidth: 'unset' }}>{icon}</ListItemIcon>
                  <ListItemText
                    sx={{ paddingLeft: 2 }}
                    primaryTypographyProps={{
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      fontWeight: isCurrentPath(item, pathname) ? 700 : 500,
                      color: '#364955'
                    }}
                    primary={text}
                  />
                  {(path === RoutePath.ResidentOverview ||
                    path === RoutePath.CRMProspectsResidents) && (
                    <ListItemIcon
                      sx={{
                        minWidth: 'unset'
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        showResidentGlobalSearchDialog();
                      }}>
                      <Search sx={{ color: '#9AAEBB !important' }} />
                    </ListItemIcon>
                  )}
                </StyledNavButton>
              </ListItem>
            </SidebarItemBadge>
          );
        })}
        <CrmEhrModuleButton
          expanded
          sx={{ mt: 'auto' }}
          handleOnClick={onResolve}
        />
        <ListItem>
          <StyledNavButton
            onClick={() => show()}
            sx={{
              border: '1px solid #D4DEE7',
              '& .MuiListItemText-root': {
                flex: 'unset'
              },
              ':hover': {
                backgroundColor: '#364955'
              }
            }}>
            <ContactSupport />{' '}
            <ListItemText
              sx={{ pl: 1 }}
              primary="Contact Support"
              primaryTypographyProps={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#0A1E28 !important'
              }}
            />
          </StyledNavButton>
        </ListItem>
        {!isCrmPath && (
          <ListItem>
            <TimeClockSidebarButton expanded />
          </ListItem>
        )}
        <ListItem>
          <FacilitySelector expanded />
        </ListItem>
        <ListItem sx={{ mb: 1 }}>
          <CurrentUser expanded handleNavigate={onResolve} />
        </ListItem>
      </List>
    </StyledDialog>
  );
};

export const showMobileNavigationDialog = create(MobileNavigationDialog);

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    boxShadow: 'unset',
    backgroundColor: '#E7EEF4',
    padding: 0
  },
  '& .MuiToolbar-root': {
    height: 64
  },
  '& .MuiCardMedia-root': {
    maxWidth: 140,
    position: 'absolute',
    margin: '0 auto',
    left: 0,
    right: 0
  },
  '& .MuiList-root': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& nav': {
    height: '100%'
  }
});
