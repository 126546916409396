import React from 'react';
import { useForm } from 'react-hook-form';
import { create, InstanceProps } from 'react-modal-promise';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from '@mui/material';

import { ControlledTextField } from '@/components/ControlledTextField';
import { useCachedPin } from '@/components/TaskModals/useCachedPin';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useSnackbar } from '@/hooks/useSnackbar';
import { ChangePinFields, useUpdateUserPin } from '@/hooks/useUserQuery';

interface ChangePinDialogProps extends InstanceProps<unknown> {}

const ChangePinDialog: React.FC<ChangePinDialogProps> = ({
  onResolve,
  isOpen
}) => {
  const { clearCachedPin } = useCachedPin();
  const currentUser = useCurrentUser().data!;
  const { showSnackbar } = useSnackbar();
  const formMethods = useForm<ChangePinFields>({
    mode: 'onSubmit',
    defaultValues: {}
  });

  const newPin = formMethods.watch('newPin');

  const mutation = useUpdateUserPin({
    onSuccess: async () => {
      showSnackbar({
        message: 'Successfully updated pin code',
        severity: 'success'
      });
      clearCachedPin();
      onResolve();
    },
    onError: async (error) => {
      if (error.response.status === 400) {
        const json = await error.response.json();
        showSnackbar({
          message: (json as any).data,
          severity: 'error'
        });
      }
    }
  });

  const handleSubmit = formMethods.handleSubmit(() =>
    mutation.mutate({
      ...formMethods.getValues(),
      id: currentUser.id
    })
  );

  // Focus the first old pin field on mount
  React.useEffect(() => {
    setTimeout(() => document.getElementById('old-pin')?.focus(), 100);
  }, []);

  return (
    <Dialog onClose={onResolve} open={isOpen} keepMounted={false}>
      <DialogTitle>
        Change PIN-code
        <IconButton size="small" onClick={onResolve}>
          <Close htmlColor="#9AAEBB" sx={{ fontSize: '1rem' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              autoComplete="off"
              id="old-pin"
              control={formMethods.control}
              fullWidth
              format="pin"
              type="text"
              name="oldPin"
              label="Old PIN-code"
              helperText={formMethods.formState.errors.oldPin?.message}
              rules={{
                required: 'Old pin code required',
                minLength: {
                  value: 4,
                  message: '4 digit pin required'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              autoComplete="off"
              control={formMethods.control}
              fullWidth
              format="pin"
              type="text"
              name="newPin"
              label="New pin code"
              helperText={formMethods.formState.errors.newPin?.message}
              rules={{
                required: 'New pin code required',
                minLength: {
                  value: 4,
                  message: '4 digit pin required'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              autoComplete="off"
              control={formMethods.control}
              fullWidth
              format="pin"
              type="text"
              name="newPinConfirm"
              label="Confirm new pin code"
              helperText={formMethods.formState.errors.newPinConfirm?.message}
              rules={{
                required: 'Confirm new pin code required',
                minLength: {
                  value: 4,
                  message: '4 digit pin required'
                },
                validate: (value) =>
                  value === newPin || 'Confirm new pin code does not match'
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onResolve}
          variant="outlined"
          sx={{ border: '1px solid #667A86', color: '#667A86', width: 137 }}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ width: 137 }}
          loading={mutation.isLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}>
          Save Pin
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const showChangePinDialog = create(ChangePinDialog);
