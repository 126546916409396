/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ScreenerSuggestion = {
    Accept: 'Accept',
    Reject: 'Reject',
    Maybe: 'Maybe',
    Pending: 'Pending',
    Cancelled: 'Cancelled',
    NA: 'N/A'
} as const;
export type ScreenerSuggestion = typeof ScreenerSuggestion[keyof typeof ScreenerSuggestion];


export function ScreenerSuggestionFromJSON(json: any): ScreenerSuggestion {
    return ScreenerSuggestionFromJSONTyped(json, false);
}

export function ScreenerSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScreenerSuggestion {
    return json as ScreenerSuggestion;
}

export function ScreenerSuggestionToJSON(value?: ScreenerSuggestion | null): any {
    return value as any;
}

