import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack, Menu } from '@mui/icons-material';
import {
  AppBar,
  AppBarProps,
  IconButton,
  Stack,
  styled,
  Toolbar
} from '@mui/material';

import { showMobileNavigationDialog } from './MobileNavigationDialog';

type MobileAppBarProps = AppBarProps &
  React.PropsWithChildren<{
    actionButtons?: React.ReactNode;
    title?: string;
    isCentered?: boolean;
    showBorder?: boolean;
    showBackButton?: boolean;
    hideNavigation?: boolean;
  }>;

export const MobileAppBar: React.FC<MobileAppBarProps> = ({
  actionButtons,
  children,
  title,
  isCentered = true,
  showBackButton = false,
  showBorder = true,
  hideNavigation = false,
  ...appBarProps
}) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <StyledAppBar
      position="sticky"
      isCentered={isCentered}
      showBorder={showBorder}
      {...appBarProps}>
      <Toolbar>
        {showBackButton ? (
          <IconButton onClick={handleBack} edge="start">
            <ArrowBack />
          </IconButton>
        ) : !hideNavigation ? (
          <IconButton onClick={showMobileNavigationDialog} edge="start">
            <Menu />
          </IconButton>
        ) : null}
        {title && <h6 className="mobile-app-title">{title}</h6>}
        {children}
        {actionButtons && (
          <Stack ml="auto" direction="row" gap={1} alignItems="center">
            {actionButtons}
          </Stack>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp(propName) {
    return propName !== 'isCentered' && propName !== 'showBorder';
  }
})<Pick<MobileAppBarProps, 'isCentered' | 'showBorder'>>(
  ({ isCentered, showBorder }) => ({
    backgroundColor: '#FCFEFF',
    borderBottom: showBorder ? '1px solid #E7EEF4' : 'none',
    boxShadow: 'unset',
    '& .MuiToolbar-root': {
      height: 64,
      display: 'flex',
      alignItems: 'center'
    },
    '& .mobile-app-title': {
      width: 'min-content',
      fontSize: '18px',
      fontWeight: 400,
      color: '#0A1E28',
      whiteSpace: 'nowrap',
      ...(isCentered && {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto'
      })
    }
  })
);
