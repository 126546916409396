import React from 'react';
import { Stack } from '@mui/material';

import { SearchBar } from '../ui/Searchbar/SearchBar';

interface FacilitySearchProps {
  value: string | null;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
}

export default function FacilitySearch({
  value,
  onChange
}: FacilitySearchProps) {
  return (
    <Stack
      direction="row"
      width="100%"
      p={2}
      alignItems="center"
      justifyContent="center">
      <SearchBar
        searchQuery={value ?? ''}
        forceDesktopVariant
        setSearchQuery={(value) =>
          onChange?.({
            target: { value }
          } as React.ChangeEvent<HTMLInputElement>)
        }
        placeholder="Search"
      />
    </Stack>
  );
}
