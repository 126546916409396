import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { UserModel } from '@/models/UserModel';
import ProfilePage from '@/pages/ProfilePage';
import ProfilePageGeneralInfo from '@/pages/ProfilePage/ProfilePageGeneralInfo';
import ProfilePageShifts from '@/pages/ProfilePage/ProfilePageShifts';

import { RoutePath } from '../RoutePath';

import { getCrmAudienceRoutes } from './getCrmAudienceRoutes';
import { getCrmCampaignRoutes } from './getCrmCampaignRoutes';
import { getCrmInboxRoutes } from './getCrmInboxRoutes';
import { getCrmOutboundCenterRoutes } from './getCrmOutboundCenterRoutes';
import { getCrmProspectRoutes } from './getCrmProspectRoutes';
import { getCrmReferralSourcesRoutes } from './getCrmReferralSourcesRoutes';
import { getCrmScreenerRoutes } from './getCrmScreenerRoutes';

export const getCrmRoutes = (currentUser: UserModel): RouteObject[] => {
  const catchAllRoute = currentUser.isScreenerCustomer()
    ? RoutePath.CRMScreenerAllLeads
    : RoutePath.CRMProspectsResidents;

  return currentUser.isCrmEnabled()
    ? [
        {
          path: RoutePath.Profile,
          element: <ProfilePage />,
          children: [
            {
              path: RoutePath.Profile,
              element: <ProfilePageGeneralInfo />
            },
            {
              path: RoutePath.ProfileShifts,
              element: <ProfilePageShifts />
            }
          ]
        },
        ...getCrmProspectRoutes(currentUser),
        ...getCrmReferralSourcesRoutes(),
        ...getCrmInboxRoutes(),
        ...getCrmOutboundCenterRoutes(),
        ...getCrmAudienceRoutes(),
        ...getCrmCampaignRoutes(),
        ...getCrmScreenerRoutes(),
        // Catch-all route for CRM module
        // If the URL is prefixed with /crm the user is redirected to the main CRM page
        {
          path: '/crm/*',
          element: <Navigate to={catchAllRoute} replace />
        }
      ]
    : [];
};
