import React from 'react';
import { Info } from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

import { DataGridContainer } from '@/components/DataGridContainer';
import {
  DateRangePicker,
  DateRangePickerProps
} from '@/components/DateRangePicker';
import GridToolbar from '@/components/GridToolbar';
import { GridToolbarQuickFilter } from '@/components/GridToolbarQuickFilter';
import {
  ALL_FACILITIES,
  selectedFacilityIdAtom
} from '@/hooks/useFacilitiesQuery';
import { useTimeClock } from '@/hooks/useTimeClock';
import { ShiftBlockSummaryModel } from '@/models/ShiftBlockSummaryModel';

import { ProfilePageTimeClock } from '../ProfilePageTimeClock';

const ProfilePageShifts: React.FC = () => {
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  const columns = React.useMemo(
    () => getColumns(isMediaQueryDownLg),
    [isMediaQueryDownLg]
  );
  const selectedFacilityId = useAtomValue(selectedFacilityIdAtom);
  const [dateRange, setDateRange] = React.useState<
    [dateFrom: Date, dateTo: Date]
  >([dayjs().subtract(14, 'days').toDate(), new Date()]);

  const { data = [], isLoading } = useTimeClock().queryUserShiftBlockSummary({
    start_date: dayjs(dateRange[0]).format('YYYY-MM-DD'),
    end_date: dayjs(dateRange[1]).format('YYYY-MM-DD'),
    ...(selectedFacilityId === ALL_FACILITIES
      ? {}
      : {
          facility_id: selectedFacilityId as string
        })
  });

  return (
    <DataGridContainer>
      <DataGridPro
        rows={data}
        columns={columns}
        loading={isLoading}
        getRowId={() => Math.random()}
        getDetailPanelContent={ShiftBlockSummaryModel.getDetailPanelContent}
        slots={{
          toolbar: () => (
            <ProfilePageShiftsToolbar
              isLoading={isLoading}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          )
        }}
      />
    </DataGridContainer>
  );
};

const getColumns = (
  isMediaQueryDownLg: boolean
): Array<GridColDef<ShiftBlockSummaryModel>> => [
  {
    field: 'shift_start',
    headerName: 'Date and Time In',
    flex: 1,
    renderCell: ({ row }) => row.renderDate('shift_start')
  },
  {
    field: 'shift_end',
    headerName: 'Date and Time Out',
    flex: 1,
    renderCell: ({ row }) => row.renderDate('shift_end')
  },
  {
    field: 'time_clocked_in',
    headerName: 'Time Spent Clocked-In',
    flex: 1
  },
  {
    field: 'time_clocked_out',
    headerName: 'Time Spent Clocked-Out',
    flex: 1
  },
  {
    field: 'total_shift_time',
    headerName: 'Total Shift Time',
    flex: 1
  },
  {
    field: 'additional_information',
    headerName: 'Additional Information',
    flex: isMediaQueryDownLg ? 0.5 : 1,
    valueGetter: ({ row }) => row.getAdditionalInformationValue(),
    renderCell: ({ row }) => row.renderAdditionalInformation(),
    renderHeader: () =>
      isMediaQueryDownLg ? <Info htmlColor="#364955" /> : undefined
  }
];

const ProfilePageShiftsToolbar: React.FC<DateRangePickerProps> = (props) => {
  return (
    <GridToolbar>
      <GridToolbarQuickFilter />
      <DateRangePicker {...props} />
      <ProfilePageTimeClock />
    </GridToolbar>
  );
};

export default ProfilePageShifts;
