import React from 'react';
import { Outlet } from 'react-router-dom';

import { MobileAppBar } from '@/components/Layout/MobileAppBar';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ProfilePageTabs } from './ProfilePageTabs';

interface ProfilePageProps {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
  const isMobileViewport = useIsMobileViewport();

  return (
    <>
      {isMobileViewport ? (
        <MobileAppBar title="Profile" />
      ) : (
        <ProfilePageTabs />
      )}
      <Outlet />
    </>
  );
};

export default ProfilePage;
