import React, { useEffect } from 'react';
import { Close, Search, Search as SearchIcon } from '@mui/icons-material';
import { Clear } from '@mui/icons-material';
import {
  Breakpoint,
  IconButton,
  SxProps,
  Theme,
  useMediaQuery
} from '@mui/material';
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';

import {
  StyledPopover,
  StyledSearchButton,
  StyledTextField
} from '@/components/GridToolbarQuickFilter';

interface SearchBar {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  popoverBreakpoint?: Breakpoint;
  placeholder?: string;
  sx?: SxProps;
  autoFocus?: boolean;
  autoFocusDependencies?: any[];
  color?: 'primary' | 'secondary';
  forceDesktopVariant?: boolean;
}

export const SearchBar: React.FC<SearchBar> = ({
  searchQuery,
  popoverBreakpoint = 'xl',
  setSearchQuery,
  placeholder = 'Search',
  sx,
  autoFocus = false,
  autoFocusDependencies,
  color = 'primary',
  forceDesktopVariant = false
}) => {
  const [focused, setFocused] = React.useState(false);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'quickFilterPopover'
  });
  const inputRef = React.createRef<HTMLInputElement>();
  React.useEffect(() => {
    if (popupState.isOpen) {
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  }, [popupState]);
  const handleSubmit = (e: React.MouseEvent | React.FormEvent) => {
    e.preventDefault();
    popupState.close();
  };
  const handleClose = () => {
    setSearchQuery('');
    popupState.close();
  };
  const isMediaQueryDownBreakpoint = forceDesktopVariant
    ? false
    : useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(popoverBreakpoint)
      );

  const handleAutoFocus = () => {
    inputRef?.current?.focus();
  };

  useEffect(() => {
    if (inputRef && autoFocus) {
      handleAutoFocus();
    }
  }, []);

  useEffect(() => {
    if (autoFocusDependencies?.length) {
      handleAutoFocus();
    }
  }, [...(autoFocusDependencies || [])]);

  const bindedTextField = (
    <StyledTextField
      sx={sx}
      className={focused ? 'focused' : ''}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      variant="standard"
      inputRef={inputRef}
      InputProps={{
        id: 'search-bar',
        startAdornment: (
          <SearchIcon
            htmlColor={color === 'secondary' ? '#667A86' : '#4E616D'}
            sx={{ marginRight: '10px', height: '0.8em', width: '0.8em' }}
          />
        ),
        endAdornment: searchQuery.length > 0 && (
          <Clear
            sx={{
              width: '18px',
              height: '18px',
              color: '#9AAEBB',
              cursor: 'pointer'
            }}
            onClick={() => setSearchQuery('')}
          />
        ),
        autoCapitalize: 'off',
        autoCorrect: 'off',
        autoComplete: 'off',
        disableUnderline: true,
        placeholder: placeholder
      }}
    />
  );

  return isMediaQueryDownBreakpoint ? (
    <>
      <StyledSearchButton
        data-testid="search-bar-button"
        color={color}
        hasSearchQuery={searchQuery.length > 0 ? true : false}
        {...bindTrigger(popupState)}>
        <Search
          fontSize="small"
          color="secondary"
          htmlColor={color === 'secondary' ? '#667A86' : undefined}
        />
      </StyledSearchButton>
      <StyledPopover {...bindPopover(popupState)}>
        <form onSubmit={handleSubmit}>{bindedTextField}</form>
        <IconButton onClick={handleClose} size="small">
          <Close htmlColor="#9AAEBB" />
        </IconButton>
      </StyledPopover>
    </>
  ) : (
    bindedTextField
  );
};
