import React from 'react';
import { Timer } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Skeleton,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import dayjs from 'dayjs';

import { showClockInDialog } from '@/components/Timeclock/ClockInDialog';
import { useTimeClock } from '@/hooks/useTimeClock';

export const ProfilePageTimeClock: React.FC = () => {
  const isMediaQueryDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  const { data, isLoading } = useTimeClock().queryClockStatus();

  const displayText = React.useMemo(() => {
    if (data?.date) {
      return `Clocked in at ${dayjs(data?.date).format('h:mma')}`;
    }

    if (!data?.date && !data?.lastClockedOutTimestamp) {
      return 'You are not clocked in';
    }

    if (!data?.date && data?.lastClockedOutTimestamp) {
      return dayjs(data.lastClockedOutTimestamp).format(
        isMediaQueryDownLg
          ? '[Clocked out] M/D/YY h:mma'
          : '[Clocked out on] M/D/YYYY [at] h:mma'
      );
    }
  }, [data, isMediaQueryDownLg]);

  return (
    <Stack direction="row" alignItems="center" gap="10px">
      {isLoading && (
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: 145 }} />
      )}
      {!isLoading && (
        <Typography sx={{ display: { xs: 'none', lg: ' auto' } }}>
          {displayText}
        </Typography>
      )}
      <LoadingButton
        id={`profile-page-time-clock-${data?.date ? 'out' : 'in'}-button`}
        loading={isLoading}
        color={data?.date ? 'warning' : 'success'}
        variant="contained"
        startIcon={<Timer />}
        onClick={async () => {
          const stream = await showClockInDialog({
            action: data?.date ? 'out' : 'in'
          });
          stream?.getTracks().forEach((track) => track.stop());
        }}>
        Clock {data?.date ? 'Out' : 'In'}
      </LoadingButton>
    </Stack>
  );
};
