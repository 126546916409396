import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ChevronRight, Group, Logout, Person } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover
} from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useRecentUsersQuery } from '@/hooks/useUserQuery';
import { RoutePath } from '@/routes/RoutePath';

import { useCachedPin } from '../TaskModals/useCachedPin';

import { RecentUsersPopover } from './RecentUsersPopover';

interface CurrentUserPopoverProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLDivElement;
  handleNavigate?: VoidFunction;
}

export const CurrentUserPopover: React.FC<CurrentUserPopoverProps> = ({
  isOpen,
  setIsOpen,
  anchorEl,
  handleNavigate
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const [isRecentUsersOpen, setIsRecentUsersOpen] =
    React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>();
  const { data: recentUsers = [], isFetching } = useRecentUsersQuery(24, {
    enabled: isOpen
  });
  const { data: currentUser } = useCurrentUser();
  const { clearCachedPin } = useCachedPin();

  const handleLogout = () => {
    clearCachedPin();
    logout({ returnTo: window.location.origin });
  };

  const navigateToProfile = () => {
    navigate(
      currentUser?.isCrmEnabled() ? RoutePath.Profile : RoutePath.EHRProfile
    );
    setIsOpen(false);
    handleNavigate?.();
  };

  return (
    <>
      <Popover
        PaperProps={{
          sx: {
            width: 245,
            boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
            borderRadius: '8px'
          }
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <List>
          <ListItemButton onClick={navigateToProfile}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText>Profile settings</ListItemText>
          </ListItemButton>
          <ListItemButton
            ref={ref as React.MutableRefObject<HTMLDivElement>}
            onClick={() => setIsRecentUsersOpen(true)}>
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText>Recent users</ListItemText>
            <IconButton onMouseOver={() => setIsRecentUsersOpen(true)}>
              {isFetching ? (
                <CircularProgress size={24} />
              ) : (
                <ChevronRight htmlColor="#667A86" />
              )}
            </IconButton>
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        </List>
      </Popover>
      <RecentUsersPopover
        anchorEl={ref.current!}
        isOpen={isRecentUsersOpen}
        setIsOpen={setIsRecentUsersOpen}
        recentUsers={recentUsers}
        isLoading={isFetching}
      />
    </>
  );
};
