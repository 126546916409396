import { useLocation } from 'react-router-dom';

import { RoutePath } from '@/routes/RoutePath';

const useIsAllFacilitiesLeadsSelected = () => {
  const location = useLocation();
  const isMatch =
    location.pathname === RoutePath.CRMScreenerAllLeads &&
    location.search === '?all_facilities=true';
  return isMatch;
};

export default useIsAllFacilitiesLeadsSelected;
