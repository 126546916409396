/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralBookInfo
 */
export interface ReferralBookInfo {
    /**
     * 
     * @type {boolean}
     * @memberof ReferralBookInfo
     */
    is_booked: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferralBookInfo
     */
    booked_by?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralBookInfo
     */
    booked_at?: string;
}

/**
 * Check if a given object implements the ReferralBookInfo interface.
 */
export function instanceOfReferralBookInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "is_booked" in value;

    return isInstance;
}

export function ReferralBookInfoFromJSON(json: any): ReferralBookInfo {
    return ReferralBookInfoFromJSONTyped(json, false);
}

export function ReferralBookInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralBookInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_booked': json['is_booked'],
        'booked_by': !exists(json, 'booked_by') ? undefined : json['booked_by'],
        'booked_at': !exists(json, 'booked_at') ? undefined : json['booked_at'],
    };
}

export function ReferralBookInfoToJSON(value?: ReferralBookInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_booked': value.is_booked,
        'booked_by': value.booked_by,
        'booked_at': value.booked_at,
    };
}

