import { Fetcher } from '@/adapters/fetcher';

export default new Fetcher({
  prefixUrl: `https://${import.meta.env.VITE_AUTH0_DOMAIN}/`,
  beforeInit: [
    async (input, init) => {
      const token = await window.getAccessTokenSilently({
        audience: import.meta.env.VITE_AUTH0_AUDIENCE
      });
      init.headers['Authorization'] = `Bearer ${token}`;
    }
  ]
});
