/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HospitalMessage
 */
export interface HospitalMessage {
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    lead_id: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    author_full_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof HospitalMessage
     */
    sent_from_exacare?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    date_sent_iso: string;
    /**
     * 
     * @type {boolean}
     * @memberof HospitalMessage
     */
    is_delivered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    author_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    source_org_name?: string;
    /**
     * 
     * @type {string}
     * @memberof HospitalMessage
     */
    connector_message_id?: string;
}

/**
 * Check if a given object implements the HospitalMessage interface.
 */
export function instanceOfHospitalMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lead_id" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "author_full_name" in value;
    isInstance = isInstance && "date_sent_iso" in value;

    return isInstance;
}

export function HospitalMessageFromJSON(json: any): HospitalMessage {
    return HospitalMessageFromJSONTyped(json, false);
}

export function HospitalMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): HospitalMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead_id': json['lead_id'],
        'message': json['message'],
        'author_full_name': json['author_full_name'],
        'sent_from_exacare': !exists(json, 'sent_from_exacare') ? undefined : json['sent_from_exacare'],
        'date_sent_iso': json['date_sent_iso'],
        'is_delivered': !exists(json, 'is_delivered') ? undefined : json['is_delivered'],
        'author_user_id': !exists(json, 'author_user_id') ? undefined : json['author_user_id'],
        'source_org_name': !exists(json, 'source_org_name') ? undefined : json['source_org_name'],
        'connector_message_id': !exists(json, 'connector_message_id') ? undefined : json['connector_message_id'],
    };
}

export function HospitalMessageToJSON(value?: HospitalMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_id': value.lead_id,
        'message': value.message,
        'author_full_name': value.author_full_name,
        'sent_from_exacare': value.sent_from_exacare,
        'date_sent_iso': value.date_sent_iso,
        'is_delivered': value.is_delivered,
        'author_user_id': value.author_user_id,
        'source_org_name': value.source_org_name,
        'connector_message_id': value.connector_message_id,
    };
}

