import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchMedications } from '@/adapters/fetchExaCare';
import { queryClient } from '@/adapters/query';

import { MARResponsePayload } from './models/MARResponsePayload';

export const QUERY_MEDICATIONS_MAR_KEY = 'useMARQuery';

export interface QueryMARSearchParams {
  resident_id: string;
  month: string | number;
  year: string | number;
}

export const invalidateMARSQuery = () =>
  queryClient.invalidateQueries([QUERY_MEDICATIONS_MAR_KEY]);

export const useMARQuery = (
  searchParams: QueryMARSearchParams,
  options: UseQueryOptions<any> = {}
) =>
  useQuery(
    [QUERY_MEDICATIONS_MAR_KEY, searchParams],
    async () => {
      const payload = await fetchMedications.get<MARResponsePayload>(
        '/medications/MAR',
        {
          searchParams,
          timeout: 30000
        }
      );
      return new MARResponsePayload(payload);
    },
    options as any
  );
