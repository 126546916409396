import React from 'react';
import { RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

const CRMScreener = loadable(() => import("@/pages/CRM/Screener")); // prettier-ignore
const CRMScreenerAllLeads = loadable(() => import("@/pages/CRM/Screener/Leads/AllLeads/AllLeads")); // prettier-ignore
const CRMScreenerSingleLead = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/SingleLead")); // prettier-ignore
const CRMScreenerAllSources = loadable(() => import("@/pages/CRM/Screener/Sources/AllSources")); // prettier-ignore
const CRMScreenerSingleSource = loadable(() => import("@/pages/CRM/Screener/Sources/SingleSource")); // prettier-ignore
const CRMScreenerRules = loadable(() => import("@/pages/CRM/Screener/Rules")); // prettier-ignore
const CRMScreenerSettings = loadable(() => import("@/pages/CRM/Screener/Settings")); // prettier-ignore
const CRMScreenerSettingsUsageOverview = loadable(() => import("@/pages/CRM/Screener/Settings/SettingsUsageOverview")); // prettier-ignore
const CRMScreenerSettingsUsageIntegrations = loadable(() => import("@/pages/CRM/Screener/Settings/SettingsIntegrations")); // prettier-ignore
const CRMScreenerSingleLeadPDPM = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/MedicareTab")); // prettier-ignore
const CRMScreenerSingleLeadReimbursementEstimator = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/ReimbursementEstimatorTab")); // prettier-ignore
const CRMScreenerSingleLeadActivity = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/CommentsTab")); // prettier-ignore
const CRMScreenerSingleLeadDetailsMobile = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/DetailsTab")); // prettier-ignore
const CRMScreenerSingleLeadAdmissionsPackageMobile = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/AdmissionsPackageTab")); // prettier-ignore
const CRMSCreenerAIAnalysis = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/AIAnalysisTab")); // prettier-ignore
const CRMScreenerSingleLeadReport = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/ReportTab")); // prettier-ignore
const CRMScreenerSingleLeadHospitalChat = loadable(() => import("@/pages/CRM/Screener/Leads/SingleLead/components/SingleLeadAnalysisTabs/HospitalChatTab/HospitalChatTab")); // prettier-ignore

const CRMScreenerSettingsDocumentIgnoreList = loadable(() => import("@/pages/CRM/Screener/Settings/DocumentIgnoreList")); // prettier-ignore
export enum CRMScreenerRoutes {
  CRMScreener = '/crm/screener',
  CRMScreenerAllSources = '/crm/screener/sources',
  CRMScreenerSingleSource = '/crm/screener/sources/:source_id',
  CRMScreenerAllLeads = '/crm/screener/leads',
  CRMScreenerSingleLead = '/crm/screener/leads/:lead_id',
  CRMScreenerSingleLeadActivity = '/crm/screener/leads/:lead_id/activities-and-comments',
  CRMScreenerSingleLeadPDPM = '/crm/screener/leads/:lead_id/medicare',
  CRMScreenerSingleLeadReimbursementEstimator = '/crm/screener/leads/:lead_id/reimbursement-estimator',
  CRMScreenerSingleLeadDetailsMobile = '/crm/screener/leads/:lead_id/details',
  CRMScreenerSingleLeadAdmissionsPackageMobile = '/crm/screener/leads/:lead_id/admissions-package',
  CRMScreenerSingleLeadAIAnalysisDataPulls = '/crm/screener/leads/:lead_id/data-pulls',
  CRMScreenerSingleLeadAIAnalysisRules = '/crm/screener/leads/:lead_id/rules',
  CRMScreenerSingleLeadReport = '/crm/screener/leads/:lead_id/report',
  CRMScreenerSingleLeadHospitalChat = '/crm/screener/leads/:lead_id/hospital-chat',
  CRMScreenerRules = '/crm/screener/rules',
  CRMScreenerRulesDataPulls = '/crm/screener/rules/data-pulls',
  CRMScreenerSettings = '/crm/screener/settings',
  CRMScreenerSettingsUsageOverview = '/crm/screener/settings/usage-overview',
  CRMScreenerSettingsIntegrations = '/crm/screener/settings/integrations',
  CRMScreenerSettingsDocumentIgnoreList = '/crm/screener/settings/document-ignore-list',
  CRMScreenerUsageOverview = '/crm/screener/usage-overview'
}

export const getCrmScreenerRoutes = (): RouteObject[] => {
  if (!FeatureFlagService.isEnabled(ExacareFeature.CRM_SCREENER)) {
    return [];
  }

  return [
    {
      path: CRMScreenerRoutes.CRMScreener,
      element: <CRMScreener />,
      children: [
        {
          path: CRMScreenerRoutes.CRMScreenerAllLeads,
          element: <CRMScreenerAllLeads />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerAllSources,
          element: <CRMScreenerAllSources />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerRules,
          element: <CRMScreenerRules />,
          children: [
            {
              path: CRMScreenerRoutes.CRMScreenerRulesDataPulls,
              element: <CRMScreenerRules />
            }
          ]
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSettings,
          element: <CRMScreenerSettings />,
          children: [
            {
              path: CRMScreenerRoutes.CRMScreenerSettingsUsageOverview,
              element: <CRMScreenerSettingsUsageOverview />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerSettingsIntegrations,
              element: <CRMScreenerSettingsUsageIntegrations />
            },
            {
              path: CRMScreenerRoutes.CRMScreenerSettingsDocumentIgnoreList,
              element: <CRMScreenerSettingsDocumentIgnoreList />
            }
          ]
        }
      ]
    },
    {
      path: CRMScreenerRoutes.CRMScreenerSingleLead,
      element: <CRMScreenerSingleLead />,
      children: [
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadDetailsMobile,
          element: <CRMScreenerSingleLeadDetailsMobile />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadAdmissionsPackageMobile,
          element: <CRMScreenerSingleLeadAdmissionsPackageMobile />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadActivity,
          element: <CRMScreenerSingleLeadActivity />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadPDPM,
          element: <CRMScreenerSingleLeadPDPM />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadReimbursementEstimator,
          element: <CRMScreenerSingleLeadReimbursementEstimator />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadAIAnalysisDataPulls,
          element: <CRMSCreenerAIAnalysis qualifierFilter="data-pulls" />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadAIAnalysisRules,
          element: <CRMSCreenerAIAnalysis qualifierFilter="rules" />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadReport,
          element: <CRMScreenerSingleLeadReport />
        },
        {
          path: CRMScreenerRoutes.CRMScreenerSingleLeadHospitalChat,
          element: <CRMScreenerSingleLeadHospitalChat />
        }
      ]
    },
    {
      path: CRMScreenerRoutes.CRMScreenerSingleSource,
      element: <CRMScreenerSingleSource />
    }
  ];
};
