/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Lead } from './Lead';
import {
    LeadFromJSON,
    LeadFromJSONTyped,
    LeadToJSON,
} from './Lead';

/**
 * 
 * @export
 * @interface PccPatient
 */
export interface PccPatient {
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    pcc_advanced_details_push_date?: string;
    /**
     * 
     * @type {string}
     * @memberof PccPatient
     */
    pcc_advanced_details_extraction_date?: string;
    /**
     * 
     * @type {Array<Lead>}
     * @memberof PccPatient
     */
    leads?: Array<Lead>;
}

/**
 * Check if a given object implements the PccPatient interface.
 */
export function instanceOfPccPatient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;

    return isInstance;
}

export function PccPatientFromJSON(json: any): PccPatient {
    return PccPatientFromJSONTyped(json, false);
}

export function PccPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccPatient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient_id': json['patient_id'],
        'pcc_advanced_details_push_date': !exists(json, 'pcc_advanced_details_push_date') ? undefined : json['pcc_advanced_details_push_date'],
        'pcc_advanced_details_extraction_date': !exists(json, 'pcc_advanced_details_extraction_date') ? undefined : json['pcc_advanced_details_extraction_date'],
        'leads': !exists(json, 'leads') ? undefined : ((json['leads'] as Array<any>).map(LeadFromJSON)),
    };
}

export function PccPatientToJSON(value?: PccPatient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient_id': value.patient_id,
        'pcc_advanced_details_push_date': value.pcc_advanced_details_push_date,
        'pcc_advanced_details_extraction_date': value.pcc_advanced_details_extraction_date,
        'leads': value.leads === undefined ? undefined : ((value.leads as Array<any>).map(LeadToJSON)),
    };
}

