/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener-intake
 * crm-screener-intake
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConnectorType = {
    WellSkyIntake: 'WellSky Intake',
    WellSkyReferralManagement: 'WellSky Referral Management',
    Fax: 'Fax',
    ManualUpload: 'Manual Upload',
    Ensocare: 'Ensocare',
    Aidin: 'Aidin',
    Aida: 'Aida',
    EpicCareLink: 'EpicCare Link',
    WellSkyReferralManagementWebReferral: 'WellSky Referral Management Web Referral',
    EnsocareVa: 'Ensocare VA'
} as const;
export type ConnectorType = typeof ConnectorType[keyof typeof ConnectorType];


export function ConnectorTypeFromJSON(json: any): ConnectorType {
    return ConnectorTypeFromJSONTyped(json, false);
}

export function ConnectorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorType {
    return json as ConnectorType;
}

export function ConnectorTypeToJSON(value?: ConnectorType | null): any {
    return value as any;
}

