import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import loadable from '@loadable/component';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import ProfilePage from '@/pages/ProfilePage';
import ProfilePageGeneralInfo from '@/pages/ProfilePage/ProfilePageGeneralInfo';
import ProfilePageShifts from '@/pages/ProfilePage/ProfilePageShifts';
import { ProtectedRoute } from '@/routes/ProtectedRoute';
import { RoutePath } from '@/routes/RoutePath';
import { ExacareFeature, FeatureFlagService } from '@/utils/featureFlagService';

import { getEhrArchivedResidentRoutes } from './getEhrArchivedResidentRoutes';
import { getEhrIncidentRoutes } from './getEhrIncidentRoutes';
import { getEhrPharmacyRoutes } from './getEhrPharmacyRoutes';
import { getEhrResidentOverviewRoutes } from './getEhrResidentOverviewRoutes';
import { getEhrResidentRoutes } from './getEhrResidentRoutes';

const TasksPageByResident = loadable(() => import("@/pages/TasksPage/TasksPageByResident")); // prettier-ignore
const TasksPageChronological = loadable(() => import("@/pages/TasksPage/TasksPageChronological")); // prettier-ignore
const TasksPageV2 = loadable(() => import("@/pages/TasksPageV2/TasksPageV2")); // prettier-ignore

export const getEhrRoutes = (): RouteObject[] => {
  const currentUser = useCurrentUser().data!;
  const userRedirectPath =
    currentUser.login_url_preference ||
    (currentUser.isScreenerCustomer()
      ? RoutePath.CRMScreenerAllLeads
      : currentUser.isCrmEnabled()
      ? '/crm/profile'
      : '/profile');

  const profileRoutes = currentUser.isCrmEnabled()
    ? [
        {
          path: '/profile',
          element: <Navigate to="/crm/profile" replace />
        },
        {
          path: '/profile/shifts',
          element: <Navigate to="/crm/profile" replace />
        }
      ]
    : [
        {
          path: '/crm/profile',
          element: <Navigate to="/profile" replace />
        },
        {
          path: '/crm/profile/shifts',
          element: <Navigate to="/profile/shifts" replace />
        }
      ];

  return [
    {
      path: RoutePath.TasksChronological,
      element: <ProtectedRoute role="L4" component={TasksPageChronological} />
    },
    {
      path: RoutePath.TasksByResident,
      element: (
        <ProtectedRoute
          role="L4"
          component={
            FeatureFlagService.isEnabled(ExacareFeature.TASKS_PAGE_V2)
              ? TasksPageV2
              : TasksPageByResident
          }
        />
      )
    },
    {
      path: RoutePath.EHRProfile,
      element: currentUser.isCrmEnabled() ? (
        <Navigate to="/crm/profile" replace />
      ) : (
        <ProfilePage />
      ),
      children: currentUser.isCrmEnabled()
        ? []
        : [
            {
              path: RoutePath.EHRProfile,
              element: <ProfilePageGeneralInfo />
            },
            {
              path: RoutePath.EHRProfileShifts,
              element: <ProfilePageShifts />
            }
          ]
    },

    ...profileRoutes,

    ...getEhrArchivedResidentRoutes(),
    ...getEhrIncidentRoutes(),
    ...getEhrPharmacyRoutes(),
    ...getEhrResidentOverviewRoutes(),
    ...getEhrResidentRoutes(),

    {
      path: '*',
      element: <Navigate to={userRedirectPath} replace />
    }
  ];
};
