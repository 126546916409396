import React from 'react';
import { Box, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { AvatarS3PhotoKey } from '@/components/ui/Avatar/AvatarS3PhotoKey';
import { ExaCareAuth0User } from '@/hooks/useCurrentUser';
import { UserPayload } from '@/hooks/useUserQuery';
import { Role } from '@/hooks/useUserTypesQuery';
import { UserDynamicPermissions } from '@/pages/AdminHub/Staff/hooks/useDynamicPermissionQuery';
import theme from '@/theme';

const rolePriorityMapping: Record<Role, number> = {
  L0: 7, // Admin
  'L0.1': 6, // Executive Director
  L1: 5, // Manager/ Head Nurse
  L2: 4, // Nurse
  L3: 3, // Medtech
  'L3.1': 2, // Caregiver (medications enabled)
  L4: 1, // Caretaker
  L5: 0 // Support Staff
};

export interface UserModel extends UserPayload, Required<ExaCareAuth0User> {}

export class UserModel extends UserPayload {
  private readonly enabledDynamicPermissions: Set<string>;

  constructor(user: UserPayload) {
    super(user);
    this.enabledDynamicPermissions = new Set(
      user?.enabled_dynamic_permissions || []
    );
  }

  public isAdmin = () => this.user_type?.type === 'L0';

  public hasMinimumUserRole = (role: Role): boolean =>
    rolePriorityMapping[this.user_type?.type as Role] >=
    rolePriorityMapping[role];

  public isRoleHigherOrEqualThan = (roleFrom: Role, roleTo: Role): boolean =>
    rolePriorityMapping[roleFrom] >= rolePriorityMapping[roleTo];

  public getFullName = (): string => `${this.first_name} ${this.last_name}`;

  public getInitials = (): string =>
    `${this.first_name?.charAt(0)}${this.last_name?.charAt(0)}`;

  public getPosition = (): string => this.user_type?.title ?? '';

  public getFormattedDateOfHire = (): string =>
    this.date_of_hire
      ? format(new Date(this.date_of_hire), 'MMMM d, y')
      : 'n/a';

  public getFormattedDateOfBirth = (): string =>
    this.dob ? format(parseISO(this.dob), 'MMMM d, y') : 'n/a';

  public renderFullNameCell = (): JSX.Element => (
    <Box display="flex" alignItems="center" gap="8px">
      <AvatarS3PhotoKey
        s3PhotoKey={this.s3_photo_key}
        sx={{ height: 32, width: 32, fontSize: '1rem' }}>
        {this.getInitials()}
      </AvatarS3PhotoKey>
      {this.getFullName()}
    </Box>
  );

  public renderDetailsCell = (): JSX.Element => (
    <Box display="flex" alignItems="center" gap="8px">
      <Box>
        <AvatarS3PhotoKey
          s3PhotoKey={this.s3_photo_key}
          sx={{ height: 32, width: 32, fontSize: '1rem' }}>
          {this.getInitials()}
        </AvatarS3PhotoKey>
      </Box>
      <Box display="column">
        <Box display="flex" alignItems="center" gap="8px">
          {this.getFullName()}
          <Typography variant="body2">{this.getPosition()}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color={theme.palette.primary.main}>
            {this.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  public clone = () => {
    const payload = Object.assign({}, this);
    const clonedUserModel = new UserModel(payload);
    return clonedUserModel;
  };

  public isBillingEnabledForOrganization = () =>
    this.organization_settings?.billing_enabled === true;

  public isBillingEnabledForUserAndOrg = () =>
    this.organization_settings?.billing_enabled === true &&
    this.enabledDynamicPermissions.has(
      UserDynamicPermissions.BILLING_AND_PRODUCT_PRICING
    );

  public isBillingEnabledForUser = () =>
    this.enabledDynamicPermissions.has(
      UserDynamicPermissions.BILLING_AND_PRODUCT_PRICING
    );

  public isAssessmentsEnabled = () =>
    this.enabledDynamicPermissions.has(
      UserDynamicPermissions.ASSESSMENT_REVIEW_AND_CARE_SUBSCRIPTIONS
    );

  public isCrmEnabled = () =>
    this.enabledDynamicPermissions.has(UserDynamicPermissions.CRM_GENERAL);

  public isDataCenterEnabled = () =>
    this.enabledDynamicPermissions.has(
      UserDynamicPermissions.DATA_CENTER_GENERAL
    );

  public isScreenerCustomer = () =>
    this.organization_settings?.is_screener_customer === true;
}
