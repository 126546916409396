/* tslint:disable */
/* eslint-disable */
/**
 * crm-screener
 * crm-screener
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadSOffenderRecord
 */
export interface LeadSOffenderRecord {
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    lead_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadSOffenderRecord
     */
    is_dismissed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    aliases?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    race?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    weight?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    hair?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    eyes?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    marks?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    is_predator?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    is_absconder?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    charges?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    address_line_1?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    zip?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadSOffenderRecord
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadSOffenderRecord
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    changes?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    external_created?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    external_last_updated?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSOffenderRecord
     */
    created_at?: string;
}

/**
 * Check if a given object implements the LeadSOffenderRecord interface.
 */
export function instanceOfLeadSOffenderRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lead_id" in value;

    return isInstance;
}

export function LeadSOffenderRecordFromJSON(json: any): LeadSOffenderRecord {
    return LeadSOffenderRecordFromJSONTyped(json, false);
}

export function LeadSOffenderRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadSOffenderRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lead_id': json['lead_id'],
        'is_dismissed': !exists(json, 'is_dismissed') ? undefined : json['is_dismissed'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middle_name': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'race': !exists(json, 'race') ? undefined : json['race'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'hair': !exists(json, 'hair') ? undefined : json['hair'],
        'eyes': !exists(json, 'eyes') ? undefined : json['eyes'],
        'marks': !exists(json, 'marks') ? undefined : json['marks'],
        'is_predator': !exists(json, 'is_predator') ? undefined : json['is_predator'],
        'is_absconder': !exists(json, 'is_absconder') ? undefined : json['is_absconder'],
        'charges': !exists(json, 'charges') ? undefined : json['charges'],
        'address_line_1': !exists(json, 'address_line_1') ? undefined : json['address_line_1'],
        'address_line_2': !exists(json, 'address_line_2') ? undefined : json['address_line_2'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'changes': !exists(json, 'changes') ? undefined : json['changes'],
        'external_created': !exists(json, 'external_created') ? undefined : json['external_created'],
        'external_last_updated': !exists(json, 'external_last_updated') ? undefined : json['external_last_updated'],
        'image_url': !exists(json, 'image_url') ? undefined : json['image_url'],
        'deleted_at': !exists(json, 'deleted_at') ? undefined : json['deleted_at'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function LeadSOffenderRecordToJSON(value?: LeadSOffenderRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_id': value.lead_id,
        'is_dismissed': value.is_dismissed,
        'first_name': value.first_name,
        'middle_name': value.middle_name,
        'last_name': value.last_name,
        'aliases': value.aliases,
        'dob': value.dob,
        'gender': value.gender,
        'race': value.race,
        'height': value.height,
        'weight': value.weight,
        'hair': value.hair,
        'eyes': value.eyes,
        'marks': value.marks,
        'is_predator': value.is_predator,
        'is_absconder': value.is_absconder,
        'charges': value.charges,
        'address_line_1': value.address_line_1,
        'address_line_2': value.address_line_2,
        'state': value.state,
        'location': value.location,
        'zip': value.zip,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'changes': value.changes,
        'external_created': value.external_created,
        'external_last_updated': value.external_last_updated,
        'image_url': value.image_url,
        'deleted_at': value.deleted_at,
        'created_at': value.created_at,
    };
}

