import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { Person, Shield } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Divider,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { HTTPError } from 'ky';

import fetchAuth0 from '@/adapters/fetchAuth0';
import { queryClient } from '@/adapters/query';
import { ControlledProfilePicture } from '@/components/ControlledProfilePicture';
import { ControlledTextField } from '@/components/ControlledTextField';
import { FormHeader } from '@/components/FormHeader';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useCheckUsername,
  useMutatePutUser,
  UserPayload
} from '@/hooks/useUserQuery';

import { ProfilePageTimeClock } from '../ProfilePageTimeClock';

import { showChangePinDialog } from './ChangePinDialog';

const ProfilePageGeneralInfo = () => {
  const isMobileViewport = useIsMobileViewport();
  const { showSnackbar } = useSnackbar();
  const currentUser = useCurrentUser().data!;
  const checkUsername = useCheckUsername();
  const formMethods = useForm<
    Partial<
      Pick<
        UserPayload,
        | 'first_name'
        | 'last_name'
        | 'email'
        | 's3_photo_key'
        | 'phone'
        | 'username'
      >
    >
  >({
    mode: 'all',
    defaultValues: {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      phone: currentUser.phone,
      s3_photo_key: currentUser.s3_photo_key,
      username: currentUser.username
    }
  });
  const updateUser = useMutatePutUser({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['useCurrentUser']);
      showSnackbar({
        message: 'Successfully updated profile',
        severity: 'success'
      });
    },
    onError: async () => {
      showSnackbar({
        message: 'Error updating profile. Please try again',
        severity: 'error'
      });
    }
  });
  const { user } = useAuth0();
  async function onResetPasswordClick() {
    try {
      const text = await fetchAuth0.text('/dbconnections/change_password', {
        method: 'POST',
        json: {
          client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
          connection: 'Username-Password-Authentication',
          email: user?.email
        }
      });
      alert(text);
    } catch (e) {
      showSnackbar({
        message: 'Server error. Please try again',
        severity: 'error'
      });
    }
  }

  async function onChangePinCodeClick() {
    await showChangePinDialog();
  }

  const handleSubmit = formMethods.handleSubmit(async (values) => {
    // Don't update email for now as we need to validate the new email in the future
    const payloadWithoutEmail = { ...values };
    delete payloadWithoutEmail.email;

    if (formMethods.formState.dirtyFields.username) {
      try {
        await checkUsername.mutateAsync(values.username!);
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 400) {
          const message = await error.response.text();
          showSnackbar({
            message,
            severity: 'error'
          });
          formMethods.setError('username', {
            type: 'manual',
            message
          });
        } else {
          showSnackbar({
            message: 'Error validating username, please try again',
            severity: 'error'
          });
        }
        return;
      }
    }

    updateUser.mutate({
      id: currentUser.id,
      ...payloadWithoutEmail
    });
  });

  return (
    <Stack
      sx={{
        gap: 2,
        p: { xs: 2, md: 3 },
        background: '#FCFEFF',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px'
      }}>
      {!isMobileViewport && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <FormHeader Icon={Person} text="Profile Details" />
          <ProfilePageTimeClock />
        </Stack>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ControlledProfilePicture
            formMethods={formMethods}
            name="s3_photo_key"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ControlledTextField
            control={formMethods.control}
            name="first_name"
            label="First Name"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ControlledTextField
            control={formMethods.control}
            name="last_name"
            label="Last Name"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item lg={6} sx={{ display: { xs: 'none', lg: 'block' } }} />
        <Grid item xs={12} sm={6} lg={3}>
          <ControlledTextField
            control={formMethods.control}
            name="phone"
            type="tel"
            inputMode="tel"
            label="Phone Number"
            format="phoneNumber"
            placeholder="(###) ###-####"
            rules={{
              required: true,
              minLength: {
                value: 10,
                message: 'Invalid phone number'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ControlledTextField
            disabled
            control={formMethods.control}
            name="email"
            label="Email"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item lg={6} sx={{ display: { xs: 'none', lg: 'block' } }} />
        <Grid item xs={12} sm={6} lg={3}>
          <ControlledTextField
            control={formMethods.control}
            name="username"
            label="Username"
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
      <Divider />
      <FormHeader Icon={Shield} text="Safety" />
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <StyledText>
            Password
            <span>***************</span>
          </StyledText>
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <UnderlinedButton variant="text" onClick={onResetPasswordClick}>
            Reset Password
          </UnderlinedButton>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={6}
          xl={8}
          sx={{ display: { xs: 'none', md: 'block' } }}
        />
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <StyledText>
            PIN-code
            <span>****</span>
          </StyledText>
        </Grid>
        <Grid item xs={6} md={4} lg={3} xl={2}>
          <UnderlinedButton variant="text" onClick={onChangePinCodeClick}>
            Change PIN-code
          </UnderlinedButton>
        </Grid>
      </Grid>
      <Divider />
      <Stack direction="row" justifyContent="flex-end" gap={1}>
        {isMobileViewport && <ProfilePageTimeClock />}
        <LoadingButton
          variant="contained"
          color="primary"
          loading={updateUser.isLoading || checkUsername.isLoading}
          disabled={!formMethods.formState.isDirty}
          onClick={handleSubmit}>
          Save Changes
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

const StyledText = styled(Typography)({
  color: '#364955',
  fontSize: '1rem',
  fontWeight: 500,
  '& > span': {
    fontWeight: 400,
    color: '#667A86',
    display: 'block'
  }
});

const UnderlinedButton = styled(Button)({
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline'
  }
});

export default ProfilePageGeneralInfo;
