import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Apartment, DoneAll, Place } from '@mui/icons-material';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  styled,
  Typography
} from '@mui/material';

import { ALL_FACILITIES } from '@/hooks/useFacilitiesQuery';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { UserModel } from '@/models/UserModel';
import useIsAllFacilitiesLeadsSelected from '@/pages/CRM/Screener/Leads/AllLeads/hooks/useIsAllFacilitiesLeadsSelected';
import useNavigateToAllFacilitiesLeads from '@/pages/CRM/Screener/Leads/AllLeads/hooks/useNavigateToAllFacilititesLeads';
import { RoutePath } from '@/routes/RoutePath';
import { UserFacilityAccess } from '@/types/users';

import FacilitySearch from './FacilitySearch';

const StyledListItemButton = styled(ListItemButton)({
  p: '12px 16px',
  '&:not(:last-of-type)': {
    borderBottom: '1px solid #D4DEE7'
  },
  '& .MuiListItemText-primary': {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#0A1E28'
  },
  '& .MuiListItemText-secondary': {
    display: 'flex',
    alignItems: 'center',
    color: '#667A86',
    position: 'relative',
    right: '2px'
  },
  '& .MuiSvgIcon-root': {
    color: '#9AAEBB'
  },
  '& .MuiListItemIcon-root': {
    minWidth: 32
  }
});

interface FacilitySelectorPopoverProps {
  selectedFacilityId: string | null;
  anchorEl: HTMLDivElement;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserModel;
  onSelectFacility: (facilityId: string) => void;
  canSelectAllFacilities: boolean;
  isCRM: boolean;
}

const FacilitySelectorPopover: React.FC<FacilitySelectorPopoverProps> = ({
  selectedFacilityId,
  onSelectFacility,
  isOpen,
  user,
  setIsOpen,
  anchorEl,
  canSelectAllFacilities,
  isCRM
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToAllFacilitiesLeads = useNavigateToAllFacilitiesLeads();

  const isMobileViewport = useIsMobileViewport();
  const [inputValue, setInputValue] = React.useState('');
  const [filteredFacilities, setFilteredFacilities] = React.useState<
    UserFacilityAccess[]
  >(user.user_facility_accesses!);
  const isAllFacilitiesLeadsSelected = useIsAllFacilitiesLeadsSelected();

  useEffect(() => {
    setFilteredFacilities(
      user.user_facility_accesses?.filter((facility) => {
        return facility.facility?.name
          ?.toLowerCase()
          .includes((inputValue ?? '').toLowerCase());
      }) ?? []
    );
  }, [inputValue, user.user_facility_accesses]);

  useEffect(() => {
    if (isAllFacilitiesLeadsSelected) {
      navigate(RoutePath.CRMScreenerAllLeads);
    }
  }, [selectedFacilityId]);

  return (
    <Popover
      PaperProps={{
        sx: {
          maxHeight: 400,
          width: isMobileViewport ? '100%' : 265,
          boxShadow: '0px 5px 20px 2px rgba(44, 58, 66, 0.15)',
          borderRadius: '8px'
        }
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      {user.user_facility_accesses?.length &&
        user.user_facility_accesses?.length > 10 && (
          <FacilitySearch
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          />
        )}
      <List sx={{ p: 0 }}>
        {location.pathname === RoutePath.CRMScreenerAllLeads && (
          <StyledListItemButton
            selected={isAllFacilitiesLeadsSelected}
            onClick={() => {
              navigateToAllFacilitiesLeads();
              setIsOpen(false);
            }}>
            <ListItemIcon>
              <DoneAll />
            </ListItemIcon>
            <ListItemText>Select All</ListItemText>
          </StyledListItemButton>
        )}
        {!isCRM && canSelectAllFacilities && (
          <StyledListItemButton
            selected={selectedFacilityId === ALL_FACILITIES}
            onClick={() => {
              onSelectFacility(ALL_FACILITIES);
              setIsOpen(false);
            }}>
            <ListItemIcon>
              <DoneAll />
            </ListItemIcon>
            <ListItemText>Select All</ListItemText>
          </StyledListItemButton>
        )}
        {filteredFacilities.length > 0 ? (
          filteredFacilities.map((item) => (
            <StyledListItemButton
              id={`community-${item.facility_id}`}
              onClick={() => onSelectFacility(item.facility_id)}
              selected={
                isAllFacilitiesLeadsSelected
                  ? false
                  : item.facility_id === selectedFacilityId
              }
              key={item.id}>
              <ListItemText
                secondary={
                  <>
                    <Place />
                    {item.facility.address}
                  </>
                }>
                {item.facility.name}
              </ListItemText>
            </StyledListItemButton>
          ))
        ) : (
          <Stack height={200} justifyContent="center" alignItems="center">
            <ListItemText sx={{ textAlign: 'center' }}>
              <Stack direction="column" alignItems="center" gap={2}>
                <Box
                  bgcolor="#B5C9D6"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1}
                  height={50}
                  width={50}>
                  <Apartment sx={{ color: '#FFFFFF !important' }} />
                </Box>
                <Typography fontSize="16px" fontWeight={500} color="#667A86">
                  No Facilities Found
                </Typography>
              </Stack>
            </ListItemText>
          </Stack>
        )}
      </List>
    </Popover>
  );
};

export default FacilitySelectorPopover;
