import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccessTimeFilled, Info } from '@mui/icons-material';
import { Icon, Tab, Tabs } from '@mui/material';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import { RoutePath } from '@/routes/RoutePath';

export const ProfilePageTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();

  const tabs = [
    {
      id: 'profile-page-general-information',
      icon: Info,
      label: 'General Information',
      path: currentUser?.isCrmEnabled()
        ? RoutePath.Profile
        : RoutePath.EHRProfile
    },
    {
      id: 'profile-page-shifts',
      icon: AccessTimeFilled,
      label: 'Shifts',
      path: currentUser?.isCrmEnabled()
        ? RoutePath.ProfileShifts
        : RoutePath.EHRProfileShifts
    }
  ];

  const activePath = tabs.find((v) => location.pathname.endsWith(v.path))?.path;

  return (
    <Tabs value={activePath} id="profile-page-tabs">
      {tabs.map(({ icon, label, path, id }, i) => {
        return (
          <Tab
            id={id}
            key={i}
            label={label}
            icon={<Icon component={icon} />}
            iconPosition="start"
            value={path}
            onClick={() => navigate(path)}
          />
        );
      })}
    </Tabs>
  );
};
